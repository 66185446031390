<template>
    <div class="d-flex" style="background: var(--v-component-base) !important">
        <div class="first">
            <v-card
                v-if="isMainFolderOpen && !isSecondaryFolderOpen"
                height="100%"
                width="100%"
                style="border-radius: 20px"
                :color="'var(--v-greyRaised-lighten1) !important'"
                :loading="loadingGuides"
            >
                <v-subheader>
                    /
                    <v-spacer></v-spacer>
                    <v-tooltip top open-delay="500">
                        <template v-slot:activator="{ on }">
                            <v-btn v-on="on" @click="createAllGuides()" icon plain :disabled="loadingGuides">
                                <v-icon large>javascript</v-icon>
                            </v-btn>
                        </template>
                        <span> Master Button </span>
                    </v-tooltip>
                    <v-tooltip top open-delay="500">
                        <template v-slot:activator="{ on }">
                            <v-btn v-on="on" @click="isLedgerOpen = true" icon plain :disabled="loadingGuides">
                                <v-icon>book</v-icon>
                            </v-btn>
                        </template>
                        <span> Ledger of Guides </span>
                    </v-tooltip>
                    <v-tooltip top open-delay="500">
                        <template v-slot:activator="{ on }">
                            <v-btn v-on="on" @click="getAllGuideFolders()" icon plain :disabled="loadingGuides">
                                <v-icon>refresh</v-icon>
                            </v-btn>
                        </template>
                        <span> Refresh All Guide Folders </span>
                    </v-tooltip>
                </v-subheader>
                <v-divider></v-divider>
                <v-list dense style="overflow-y: scroll; height: 75vh">
                    <v-list-item v-for="(guideFolder, index) in guideFolders" :key="index" @click="getMainFolderConfig(guideFolder)" :disabled="loadingGuides">
                        <v-icon color="amber" style="padding-right: 8px"> folder </v-icon>
                        {{ guideFolder.folder }}
                    </v-list-item>
                </v-list>
            </v-card>

            <v-card
                v-if="!isMainFolderOpen && isSecondaryFolderOpen"
                height="100%"
                width="100%"
                style="border-radius: 20px"
                :color="'var(--v-greyRaised-lighten1) !important'"
                :loading="loadingGuides"
            >
                <v-subheader>
                    /{{ selectedFolder }}/
                    <v-spacer></v-spacer>
                    <v-tooltip top open-delay="500">
                        <template v-slot:activator="{ on }">
                            <v-btn v-on="on" @click="closeMainFolder()" icon plain :disabled="loadingGuides" style="border-radius: 20px">
                                <v-icon>arrow_back</v-icon>
                            </v-btn>
                        </template>
                        <span> Close Folder </span>
                    </v-tooltip>
                </v-subheader>
                <v-divider></v-divider>
                <v-list dense style="overflow-y: scroll; height: 765px">
                    <v-list-item v-for="(folder, index) in secondaryFolders" :key="index" @click="getSecondaryFolders(folder)" :disabled="loadingGuides">
                        <v-icon color="amber" style="padding-right: 8px"> folder </v-icon>
                        {{ folder.secondaryFolder }}
                    </v-list-item>
                </v-list>
            </v-card>

            <v-card
                v-if="!isMainFolderOpen && !isSecondaryFolderOpen"
                height="100%"
                width="100%"
                style="border-radius: 20px"
                :color="'var(--v-greyRaised-lighten1) !important'"
                :loading="loadingGuides"
            >
                <v-subheader>
                    /{{ selectedFolder }}/{{ secondFolder }}/
                    <v-spacer></v-spacer>
                    <v-tooltip top open-delay="500">
                        <template v-slot:activator="{ on }">
                            <v-btn v-on="on" @click="newGuideModal = true" icon plain :disabled="loadingGuides" style="border-radius: 20px">
                                <v-icon large>javascript</v-icon>
                            </v-btn>
                        </template>
                        <span> Create New Guide </span>
                    </v-tooltip>
                    <v-tooltip top open-delay="500">
                        <template v-slot:activator="{ on }">
                            <v-btn v-on="on" @click="closeSecondaryFolder()" icon plain :disabled="loadingGuides" style="border-radius: 20px">
                                <v-icon>arrow_back</v-icon>
                            </v-btn>
                        </template>
                        <span> Close Folder </span>
                    </v-tooltip>
                </v-subheader>
                <v-divider></v-divider>
                <v-list dense style="overflow-y: scroll; height: 765px">
                    <v-list-item v-for="(guide, index) in guides" :key="index" @click="getGuideSteps(guide)" :disabled="loadingGuides">
                        <v-icon color="amber" style="padding-right: 8px"> data_object </v-icon>
                        {{ guide.guideName }}
                    </v-list-item>
                </v-list>
            </v-card>
        </div>
        <div class="second">
            <v-card
                v-if="selectedGuide && !isStepTrashOpen"
                height="100%"
                width="100%"
                style="border-radius: 20px"
                :color="'var(--v-greyRaised-lighten1) !important'"
                :loading="loadingGuideConfig"
            >
                <v-subheader>
                    Selected Guide Steps: {{ selectedGuide.guideId }}
                    <v-spacer></v-spacer>
                    <v-tooltip top open-delay="500">
                        <template v-slot:activator="{ on }">
                            <v-btn v-on="on" @click="isStepTrashOpen = true" icon plain>
                                <v-icon color="grey">restore_from_trash</v-icon>
                            </v-btn>
                        </template>
                        <span> Restore Deleted Steps </span>
                    </v-tooltip>
                    <v-tooltip top open-delay="500">
                        <template v-slot:activator="{ on }">
                            <v-btn v-on="on" @click="closeGuideConfig()" icon plain color="redPop"><v-icon>close</v-icon></v-btn>
                        </template>
                        <span> Close Selected Guide </span>
                    </v-tooltip>
                </v-subheader>
                <v-divider></v-divider>
                <div style="height: calc(100% - 48px); overflow-y: scroll">
                    <div class="py-2" v-for="(step, i) in selectedGuide.steps" :key="i">
                        <div class="d-flex">
                            <div style="height: 60px; width: 40px" class="d-flex justify-center align-center">
                                <span style="font-size: 20px; opacity: 0.5">{{ i }}</span>
                            </div>
                            <v-card
                                @click="toggleStep(i)"
                                :color="'var(--v-greyRaised-lighten1) !important'"
                                style="height: 60px; width: 100%; border: 1px solid var(--v-greyDarker-lighten2); contain: content"
                            >
                                <v-subheader style="height: 30px" class="px-2 py-0">
                                    {{ step.popover.title }} <i class="ml-2" style="opacity: 0.6">({{ step.element }})</i>
                                </v-subheader>
                                <div class="px-2">
                                    <span style="font-size: 12px; opacity: 0.7">{{ step.popover.description }}</span>
                                </div>
                                <div v-if="expandedStep === null" style="position: absolute; right: 4px; top: 4px">
                                    <v-icon small style="opacity: 0.7" color="primary">arrow_forward</v-icon>
                                </div>
                                <div v-if="expandedStep != null" style="position: absolute; right: 4px; top: 4px">
                                    <v-icon small style="opacity: 0.7" color="primary">arrow_back</v-icon>
                                </div>
                            </v-card>
                            <div style="height: 60px; width: 40px" class="d-flex justify-center align-center">
                                <li>
                                    <v-icon icon :disabled="loadingGuideConfig" @click="moveStepUp(step, i)">arrow_upward</v-icon>
                                    <v-icon icon :disabled="loadingGuideConfig" @click="moveStepDown(step, i)">arrow_downward</v-icon>
                                </li>
                            </div>
                        </div>
                        <v-expand-transition>
                            <div class="d-flex" v-if="isStepExpanded(i)">
                                <div style="height: 60px; width: 40px" class="d-flex justify-center align-center"></div>
                                <div style="height: auto; width: 100%; border: 1px solid var(--v-greyDarker-lighten2)">
                                    <v-row no-gutters class="pa-2 pb-0">
                                        <v-col cols="12" sm="2">
                                            <v-text-field
                                                v-model="step.popover.title"
                                                class="pb-2"
                                                clearable
                                                dense
                                                hide-details
                                                outlined
                                                placeholder="Title"
                                                persistent-placeholder
                                            ></v-text-field>
                                            <v-text-field
                                                :disabled="true"
                                                v-model="step.element"
                                                class="pb-2"
                                                dense
                                                hide-details
                                                outlined
                                                placeholder="Element"
                                                persistent-placeholder
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="8"></v-col>
                                        <v-col cols="12" sm="2" class="pb-2">
                                            <div style="height: 100%; border: 1px solid var(--v-greyDarker-lighten2)">
                                                <v-subheader style="height: 30px" class="pl-2"> Position </v-subheader>
                                                <div class="d-flex align-center justify-center">
                                                    <v-text-field
                                                        v-model="step.popover.position"
                                                        class="pb-2"
                                                        dense
                                                        hide-details
                                                        outlined
                                                        placeholder="Position"
                                                        persistent-placeholder
                                                    ></v-text-field>
                                                    <!-- <v-btn-toggle mandatory style="border-radius: 16px" v-model="step.popover.position">
                                                        <v-btn icon :color="step.popover.position == 'left' ? 'primary' : 'var(--v-greyRaised-base)'"><v-icon>arrow_back</v-icon></v-btn>
                                                        <v-btn icon :color="step.popover.position == 'top' ? 'primary' : 'var(--v-greyRaised-base)'"><v-icon>arrow_upward</v-icon></v-btn>
                                                        <v-btn icon :color="step.popover.position == 'right' ? 'primary' : 'var(--v-greyRaised-base)'"><v-icon>arrow_forward</v-icon></v-btn>
                                                        <v-btn icon :color="step.popover.position == 'bottom' ? 'primary' : 'var(--v-greyRaised-base)'"><v-icon>arrow_downward</v-icon></v-btn>
                                                    </v-btn-toggle> -->
                                                </div>
                                            </div>
                                        </v-col>
                                        <v-col cols="12" sm="12">
                                            <v-textarea v-model="step.popover.description" hide-details outlined placeholder="Description" persistent-placeholder></v-textarea>
                                        </v-col>
                                    </v-row>
                                    <div style="width: 100%" class="d-flex justify-end align-center">
                                        <v-btn @click="deleteStep(i)" text plain color="redPop">Delete</v-btn>
                                        <v-btn @click="saveGuideStep(step, i)" text plain color="primary">Save</v-btn>
                                    </div>
                                </div>
                                <div style="height: 60px; width: 40px" class="d-flex justify-center align-center"></div>
                            </div>
                        </v-expand-transition>
                    </div>
                </div>
            </v-card>

            <v-card
                v-if="selectedGuide && isStepTrashOpen"
                height="100%"
                width="100%"
                style="border-radius: 20px"
                :color="'var(--v-greyRaised-lighten1) !important'"
                :loading="loadingGuideConfig"
            >
                <v-subheader>
                    Selected Guide Steps: {{ selectedGuide.guideId }}
                    <v-spacer></v-spacer>
                    <v-tooltip top open-delay="500">
                        <template v-slot:activator="{ on }">
                            <v-btn v-on="on" @click="isStepTrashOpen = false" icon plain>
                                <v-icon color="grey">arrow_back</v-icon>
                            </v-btn>
                        </template>
                        <span> Exit Trashcan </span>
                    </v-tooltip>
                    <v-tooltip top open-delay="500">
                        <template v-slot:activator="{ on }">
                            <v-btn v-on="on" @click="closeGuideConfig()" icon plain color="redPop"><v-icon>close</v-icon></v-btn>
                        </template>
                        <span> Close Selected Guide </span>
                    </v-tooltip>
                </v-subheader>
                <v-divider></v-divider>
                <div style="height: calc(100% - 48px); overflow-y: scroll">
                    <div class="py-2" v-for="(step, i) in selectedGuide.deletedSteps" :key="i">
                        <div class="d-flex">
                            <div style="height: 60px; width: 40px" class="d-flex justify-center align-center">
                                <span style="font-size: 20px; opacity: 0.5">{{ i }}</span>
                            </div>
                            <v-card
                                @click="toggleStep(i)"
                                :color="'var(--v-greyRaised-lighten1) !important'"
                                style="height: 60px; width: 100%; border: 1px solid var(--v-greyDarker-lighten2); contain: content"
                            >
                                <v-subheader style="height: 30px" class="px-2 py-0">
                                    {{ step.popover.title }} <i class="ml-2" style="opacity: 0.6">({{ step.element }})</i>
                                </v-subheader>
                                <div class="px-2">
                                    <span style="font-size: 12px; opacity: 0.7">{{ step.popover.description }}</span>
                                </div>
                                <div v-if="expandedStep === null" style="position: absolute; right: 4px; top: 4px">
                                    <v-icon small style="opacity: 0.7" color="primary">arrow_forward</v-icon>
                                </div>
                                <div v-if="expandedStep != null" style="position: absolute; right: 4px; top: 4px">
                                    <v-icon small style="opacity: 0.7" color="primary">arrow_back</v-icon>
                                </div>
                            </v-card>
                            <div style="height: 60px; width: 40px" class="d-flex justify-center align-center">
                                <v-icon>drag_indicator</v-icon>
                            </div>
                        </div>
                        <v-expand-transition>
                            <div class="d-flex" v-if="isStepExpanded(i)">
                                <div style="height: 60px; width: 40px" class="d-flex justify-center align-center"></div>
                                <div style="height: auto; width: 100%; border: 1px solid var(--v-greyDarker-lighten2)">
                                    <v-row no-gutters class="pa-2 pb-0">
                                        <v-col cols="12" sm="2">
                                            <v-text-field
                                                v-model="step.popover.title"
                                                class="pb-2"
                                                clearable
                                                dense
                                                hide-details
                                                outlined
                                                placeholder="Title"
                                                persistent-placeholder
                                            ></v-text-field>
                                            <v-text-field
                                                v-model="step.element"
                                                :disabled="true"
                                                class="pb-2"
                                                dense
                                                hide-details
                                                outlined
                                                placeholder="Element"
                                                persistent-placeholder
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="8"></v-col>
                                        <v-col cols="12" sm="2" class="pb-2">
                                            <div style="height: 100%; border: 1px solid var(--v-greyDarker-lighten2)">
                                                <v-subheader style="height: 30px" class="pl-2"> Position </v-subheader>
                                                <div class="d-flex align-center justify-center">
                                                    <!-- <v-btn-toggle mandatory style="border-radius: 16px" v-model="step.popover.position">
                                                        <v-btn icon :color="step.popover.position == 'left' ? 'primary' : 'var(--v-greyRaised-base)'"><v-icon>arrow_back</v-icon></v-btn>
                                                        <v-btn icon :color="step.popover.position == 'top' ? 'primary' : 'var(--v-greyRaised-base)'"><v-icon>arrow_upward</v-icon></v-btn>
                                                        <v-btn icon :color="step.popover.position == 'right' ? 'primary' : 'var(--v-greyRaised-base)'"><v-icon>arrow_forward</v-icon></v-btn>
                                                        <v-btn icon :color="step.popover.position == 'bottom' ? 'primary' : 'var(--v-greyRaised-base)'"><v-icon>arrow_downward</v-icon></v-btn>
                                                    </v-btn-toggle> -->
                                                </div>
                                            </div>
                                        </v-col>
                                        <v-col cols="12" sm="12">
                                            <v-textarea v-model="step.popover.description" hide-details outlined placeholder="Description" persistent-placeholder></v-textarea>
                                        </v-col>
                                    </v-row>
                                    <div style="width: 100%" class="d-flex justify-end align-center">
                                        <v-btn @click="restoreGuideStep(step, i)" text plain color="primary">Restore</v-btn>
                                    </div>
                                </div>
                                <div style="height: 60px; width: 40px" class="d-flex justify-center align-center"></div>
                            </div>
                        </v-expand-transition>
                    </div>
                </div>
            </v-card>
        </div>

        <v-dialog v-model="newGuideModal" width="800px" persistent>
            <v-card>
                <v-subheader style="font-size: 16px">
                    Create New Guide from an Existing Guide
                    <v-spacer></v-spacer>
                    <v-btn icon plain color="secondary" :disabled="!newGuideName" @click="createGuide()"><v-icon>save</v-icon></v-btn>
                    <v-btn icon color="redPop" plain @click="newGuideModal = false"><v-icon>close</v-icon></v-btn>
                </v-subheader>
                <v-divider></v-divider>
                <div class="px-4">
                    <v-text-field v-model="newGuideName" autofocus outlined dense clearable hide-details></v-text-field>
                </div>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
export default {
    data: () => ({
        /* First Div */
        isMainFolderOpen: true,
        isSecondaryFolderOpen: false,
        /* Second Div */
        isGuideOpen: false,
        toggle: 2,

        steps: null,
        newGuideModal: false,
        newGuideName: null,
        isStepTrashOpen: false,
        isFolderOpen: false,
        selectedGuide: null,
        selectedFolder: null,
        loadingGuideConfig: false,
        loadingGuides: false,
        guides: [],
        guideFolders: [],

        expandedStep: null,
        secondaryFolders: [],
        secondFolder: null,
    }),

    computed: {
        isStepExpanded() {
            return (index) => this.expandedStep === index;
        },
    },

    created() {
        this.getAllGuideFolders();
    },

    methods: {
        toggleStep(index) {
            this.loadingGuideConfig = true;
            this.expandedStep = this.expandedStep === index ? null : index;
            this.loadingGuideConfig = false;
        },

        async createGuide() {
            try {
                let obj = { guideId: `${this.selectedFolder}/${this.secondFolder}/Steps` };
                let result = await this.$API.getGuideSteps(obj);
                let newGuide = {
                    guideId: `${this.selectedFolder}/${this.secondFolder}/${this.newGuideName}`,
                    guideName: this.newGuideName,
                    steps: result.steps,
                    deletedSteps: result.deletedSteps,
                    isDeleted: false,
                    isActive: true,
                    createdAt: new Date().toISOString(),
                    updatedAt: new Date().toISOString(),
                };
                await this.$API.createGuide(newGuide);
                return this.$message.success("Success");
            } catch (e) {
                this.$message.error("Failure");
                return console.log(e);
            } finally {
                this.newGuideModal = false;
                this.newGuideName = null;
            }
        },

        closeGuideConfig() {
            this.expandedStep = null;
            this.selectedGuide = null;
            this.isStepTrashOpen = false;
        },

        async createAllGuides() {
            try {
                this.loadingGuides = true;
                // await this.$API.createAllGuides()
                await this.$API.createAllMissingSteps();
                await this.$API.getAllGuideFolders();
            } catch (e) {
                console.log(e);
                return this.$message.error("Failure");
            } finally {
                this.loadingGuides = false;
            }
        },

        async restoreGuideStep(step, index) {
            try {
                this.loadingGuideConfig = true;
                let guide = this.selectedGuide;
                let tgtStep = guide.deletedSteps.splice(index, 1);
                let updatedStep = {
                    element: tgtStep[0].element,
                    popover: tgtStep[0].popover,
                    createdAt: tgtStep[0].createdAt,
                    updatedAt: new Date().toISOString(),
                };
                guide.steps.push(updatedStep);
                let newSteps = guide.steps;
                let newDeletedSteps = guide.deletedSteps;
                let updatedGuide = {
                    guideId: guide.guideId,
                    guideName: guide.guideName,
                    steps: newSteps,
                    deletedSteps: newDeletedSteps,
                    isActive: guide.isActive,
                    isDeleted: guide.isDeleted,
                    createdAt: guide.createdAt,
                    updatedAt: new Date().toISOString(),
                };
                this.selectedGuide = updatedGuide;
                await this.$API.updateGuide(updatedGuide);
                return this.$message.success("Success");
            } catch (e) {
                console.log(e);
                return this.$message.error("Failure");
            } finally {
                this.loadingGuideConfig = false;
            }
        },

        async getMainFolderConfig(item) {
            try {
                this.isLedgerOpen = false;
                this.loadingGuides = true;
                this.isMainFolderOpen = false;
                this.isSecondaryFolderOpen = true;
                this.selectedFolder = item.folder;
                let result = await this.$API.getGuideMainFolderConfig({
                    folder: item.folder,
                });
                let arr = new Set();
                for (let folder of result) {
                    folder = folder.guideName.split("/");
                    // let obj = {
                    //     secondaryFolder: folder[1],
                    // };
                    arr.add(JSON.stringify(folder[1]));
                }
                let arr3 = [];
                const arr2 = Array.from(arr).map(JSON.parse);
                for (let element of arr2) {
                    let obj = {
                        secondaryFolder: element,
                    };
                    arr3.push(obj);
                }
                this.secondaryFolders = arr3;
                this.isFolderOpen = true;
                this.loadingGuides = false;
            } catch (e) {
                console.log(e);
                return this.$message.error("Failure");
            }
        },

        closeMainFolder() {
            this.loadingGuides = true;
            this.isSecondaryFolderOpen = false;
            this.isMainFolderOpen = true;
            this.loadingGuides = false;
        },

        closeSecondaryFolder() {
            this.loadingGuides = true;
            this.isStepTrashOpen = false;
            this.expandedStep = null;
            this.selectedGuide = null;
            this.isSecondaryFolderOpen = true;
            this.loadingGuides = false;
        },

        async getAllGuideFolders() {
            try {
                this.loadingGuides = true;
                let result = await this.$API.getAllGuideFolders();
                console.log("guideFolders", result);
                this.guideFolders = result;
            } catch (e) {
                this.$message.error("Failure");
                return console.log(e);
            } finally {
                this.loadingGuides = false;
            }
        },

        async getSecondaryFolders(folder) {
            try {
                this.loadingGuides = true;
                this.isSecondaryFolderOpen = false;
                this.secondFolder = folder.secondaryFolder;
                let key = `${this.selectedFolder}/${folder.secondaryFolder}`;
                let obj = { key };
                let result = await this.$API.getFolderGuides(obj);
                this.guides = result;
                return;
            } catch (e) {
                this.$message.error("Failure");
                return console.log(e);
            } finally {
                this.loadingGuides = false;
            }
        },

        async getGuideSteps(data) {
            this.loadingGuides = true;
            try {
                console.log(data);
                let obj = { guideId: data.guideId };
                let result = await this.$API.getGuideSteps(obj);
                this.selectedGuide = result;
                return;
            } catch (e) {
                this.$message.error("Failure");
                return console.log(e);
            } finally {
                this.loadingGuides = false;
            }
        },

        async moveStepUp(step, index) {
            try {
                this.loadingGuideConfig = true;
                let newIndex = index - 1;
                if (newIndex >= 0) {
                    let temp = this.selectedGuide.steps[newIndex];
                    this.selectedGuide.steps[newIndex] = step;
                    this.selectedGuide.steps[index] = temp;
                    let buffer = this.selectedGuide;
                    this.selectedGuide = null; // ???
                    this.selectedGuide = buffer;

                    let updatedGuide = {
                        guideId: this.selectedGuide.guideId,
                        guideName: this.selectedGuide.guideName,
                        steps: this.selectedGuide.steps,
                        deletedSteps: this.selectedGuide.deletedSteps,
                        isActive: this.selectedGuide.isActive,
                        isDeleted: this.selectedGuide.isDeleted,
                        createdAt: this.selectedGuide.createdAt,
                        updatedAt: new Date().toISOString(),
                    };
                    this.selectedGuide = updatedGuide;
                    await this.$API.updateGuide(updatedGuide);
                    return this.$message.success("Success");
                } else {
                    throw new Error("Out Of Bounds");
                }
            } catch (e) {
                this.$message.error("Failure");
                return console.log(e);
            } finally {
                this.loadingGuideConfig = false;
            }
        },

        async moveStepDown(step, index) {
            try {
                this.loadingGuideConfig = true;
                let newIndex = index + 1;
                if (newIndex <= this.selectedGuide.steps.length - 1) {
                    let temp = this.selectedGuide.steps[newIndex];
                    this.selectedGuide.steps[newIndex] = step;
                    this.selectedGuide.steps[index] = temp;
                    let buffer = this.selectedGuide;
                    this.selectedGuide = null; // ???
                    this.selectedGuide = buffer;

                    let updatedGuide = {
                        guideId: this.selectedGuide.guideId,
                        guideName: this.selectedGuide.guideName,
                        steps: this.selectedGuide.steps,
                        deletedSteps: this.selectedGuide.deletedSteps,
                        isActive: this.selectedGuide.isActive,
                        isDeleted: this.selectedGuide.isDeleted,
                        createdAt: this.selectedGuide.createdAt,
                        updatedAt: new Date().toISOString(),
                    };
                    this.selectedGuide = updatedGuide;
                    await this.$API.updateGuide(updatedGuide);
                    return this.$message.success("Success");
                } else {
                    throw new Error("Out Of Bounds");
                }
            } catch (e) {
                this.$message.error("Failure");
                return console.log(e);
            } finally {
                this.loadingGuideConfig = false;
            }
        },

        async deleteStep(i) {
            try {
                this.loadingGuideConfig = true;
                let guide = this.selectedGuide;
                let tgtStep = guide.steps.splice(i, 1);
                let updatedStep = {
                    element: tgtStep[0].element,
                    popover: tgtStep[0].popover,
                    createdAt: tgtStep[0].createdAt,
                    updatedAt: new Date().toISOString(),
                };
                let newSteps = guide.steps;
                guide.deletedSteps.push(updatedStep);
                let newDeletedSteps = guide.deletedSteps;
                let updatedGuide = {
                    guideId: guide.guideId,
                    guideName: guide.guideName,
                    steps: newSteps,
                    deletedSteps: newDeletedSteps,
                    isActive: guide.isActive,
                    isDeleted: guide.isDeleted,
                    createdAt: guide.createdAt,
                    updatedAt: new Date().toISOString(),
                };
                this.selectedGuide = updatedGuide;
                await this.$API.updateGuide(updatedGuide);
                return this.$message.success("Success");
            } catch (e) {
                this.$message.error("Failure");
                return console.log(e);
            } finally {
                this.loadingGuideConfig = false;
            }
        },

        async saveGuideStep(step, index) {
            try {
                this.loadingGuideConfig = true;
                let obj = {
                    element: step.element,
                    popover: {
                        title: step.popover.title,
                        description: step.popover.description,
                        position: step.popover.position,
                    },
                    createdAt: step.createdAt,
                    updatedAt: new Date().toISOString(),
                };
                let guide = this.selectedGuide;
                guide.steps[index] = obj;
                let updatedGuide = {
                    guideId: guide.guideId,
                    guideName: guide.guideName,
                    steps: guide.steps,
                    deletedSteps: guide.deletedSteps,
                    isActive: guide.isActive,
                    isDeleted: guide.isDeleted,
                    createdAt: guide.createdAt,
                    updatedAt: new Date().toISOString(),
                };
                this.selectedGuide = updatedGuide;
                await this.$API.updateGuide(updatedGuide);
                return this.$message.success("Success");
            } catch (e) {
                this.$message.error("Failure");
                return console.log(e);
            } finally {
                this.loadingGuideConfig = false;
            }
        },

    },
};
</script>
<style>
/* Zenon's Piece */
.first {
    height: calc(100vh - 18vh);
    width: 25%;
    padding: 8px 4px 8px 8px;
}

.second {
    height: calc(100vh - 18vh);
    width: 75%;
    padding: 8px 8px 8px 4px;
}
</style>
